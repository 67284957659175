<template>
  <div class="content">
      <img @click="back" src="@/assets/images/back_fff.png" alt="">
      <span>{{title}}</span>
  </div>
</template>

<script>
export default {
  name: "Vue",
  props: {
    title: {
      type: String,
      default() {
        return '详情';
      },
    },
  },
  data() {
    return {};
  },

  components: {},

  created() {},

  methods: {
      //返回上一页
      back(){
          this.$router.back();
      },
  },
};
</script>
<style lang='less' scoped>
.content{
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 44px;
    position: relative;
    background-color: #35a3bd;
    img{
        width: 2rem;
        height: 2rem;
        position: absolute;
        left: 20px;
        cursor: pointer;
    }
    span{
        font-size: 1rem;
        color: #fff;
    }
}
</style>