<template>
  <div class="content min_box" v-wechat-title="'动态详情'">
    <nav-bar title="动态详情" />
    <div class="news_center" v-if="type == 1">
      <h2>{{ detail.news_name }}</h2>
      <div class="name">{{ detail.auther }}</div>
      <div class="time" v-if="detail.issued_time">
        {{ detail.issued_time.split(" ")[0] }}
      </div>
      <div v-html="detail.news_detail" class="news_html"></div>
    </div>
    <div class="news_center" v-else>
      <video
        v-if="detail.video"
        :src="detail.video"
        controls
      ></video>
      <h2>{{ detail.news_name }}</h2>
      <div class="name">{{ detail.auther }}</div>
      <div class="time" v-if="detail.issued_time">
        {{ detail.issued_time.split(" ")[0] }}
      </div>
      <div v-html="detail.news_detail" class="news_html"></div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/nav/nav";
import { newdetail } from "@/api/http";
export default {
  name: "Vue",
  data() {
    return {
      type: 1,
      detail: {}
    };
  },

  components: { navBar },

  created() {
    this.type = this.$route.params.type;
    newdetail({
      id: this.$route.params.id,
    }).then((res) => {
      this.detail = res.data.newscontents;
    });
  },
};
</script>
<style lang='less' scoped>
.news_center {
  padding: 20px;
  h2 {
    font-size: 1.4rem;
    color: #333;
  }
  .name {
    margin: 10px 0;
    font-size: 0.8rem;
    color: #888;
  }
  .time {
    margin-bottom: 14px;
    color: #888;
    font-size: 0.8rem;
  }
  .news_html {
    font-size: 1rem;
  }
  video {
    width: 100%;
    height: 15rem;
    background-color: #000;
  }
}
</style>